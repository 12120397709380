<template>
    <!--编辑页与详情页相同-->
    <div class="PurchaseInDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form :id="formId" ref="form" label-width="80px" width="auto" size="small">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="单据号">
                                <el-input placeholder="" v-model="form.code" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="状态">
                                <el-input placeholder="" v-model="form.stockStatus" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="配送中心">
                                <el-input placeholder="" v-model="form.repoName" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="采购机构">
                                <el-input placeholder="" v-model="form.deptName" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建人">
                                <el-input placeholder="" v-model="form.creator" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建日期">
                                <el-input placeholder="" v-model="form.createTime" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="操作人">
                                <el-input placeholder="" v-model="form.updater" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="操作时间">
                                <el-input placeholder="" v-model="form.updateTime" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div style="height: 32px">
                <el-button style="float: right; margin-top: 3px" size="mini" @click="handlePrint">打印</el-button>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                stripe
                style="width: 100%"
                :data="tableData"
                v-loading="tableLoading"
                size="mini"
                show-summary
                :highlight-current-row="true"
                max-height="440"
                :id="tableId"
            >
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="商品名称" width="240" prop="sku.name" />
                <el-table-column label="条码" width="160" prop="sku.bars" />
                <el-table-column prop="sku.specs" label="规格" width="100" />
                <el-table-column prop="sku.category" label="类目" width="120" />
                <el-table-column prop="sku.unit" label="单位" width="80" />
                <el-table-column prop="sku.brand" label="品牌" width="100" />
                <el-table-column label="包装因子" width="80" v-if="bigUnitFlag">
                    <template slot-scope="scope">
                        {{ scope.row.packFactor }}
                    </template>
                </el-table-column>
                <el-table-column label="退货价" width="80" prop="prices" v-if="showInPrice()" />
                <el-table-column label="退货箱数" width="80" prop="counts" />
                <el-table-column label="散装数量" width="80" prop="bulkCounts" v-if="bigUnitFlag" />
                <el-table-column label="退货总数" width="80" prop="orderCounts" />
                <el-table-column label="退货金额" width="82" prop="refundMoney" v-if="showInPrice()" />
                <el-table-column label="退货赠品数量" width="130" prop="giftCounts" />
            </el-table>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from 'js/UrlUtils';
import MoneyUtils from 'js/MoneyUtils';

export default {
    name: 'RefundOutDetail',
    props: ['form'],
    data() {
        return {
            url: {
                page: '/goods/goods/list_c',
            },
            codes: [],
            tableData: [],
            bigUnitFlag: false,
            tableLoading: true,
            //id标识
            formId: 'form',
            tableId: 'table',
        };
    },
    mounted() {
        this.codes = this.form.goodsCode;
        if (this.form.stockStatus == 1) {
            this.form.stockStatus = '已出库';
        } else {
            this.form.stockStatus = '待出库';
        }
        this.getUnitFlag(this.form.deptCode);
    },
    methods: {
        handlePrint() {
            this.printIdSelectorHtml(
                [
                    { id: this.formId, type: 'form' },
                    { id: this.tableId, type: 'table' },
                ],
                this.$route.meta.title || ''
            );
        },
        getUnitFlag(code) {
            UrlUtils.HasBigUnitPrivilege(this, code, (flag) => {
                this.bigUnitFlag = flag;
            });
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            UrlUtils.QueryGoodsInfoByCodes(_this, _this.url.page, { codes: this.codes }, (data) => {
                data.forEach((v, i) => {
                    v.packFactor = this.form.packFactors[i];
                    v.prices = MoneyUtils.moneyToThree(this.form.prices[i]);
                    v.orderCounts = this.form.orderCounts[i];
                    v.bulkCounts = this.form.bulkCounts[i];
                    v.counts = this.form.counts[i];
                    v.giftCounts = this.form.giftCounts[i];
                    v.refundMoney = this.decimal(v.counts).mul(v.prices).toNumber();
                });
                _this.tableData = data;
            });
        },
    },
    filters: {
        type(type) {
            if (typeof type == 'undefined') {
                return '';
            }
            if (type == '0') {
                return '标品';
            }
            if (type == '1') {
                return '非标品';
            }
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
